<template>
  <div>
    Vendor Profile
  </div>
</template>

<script>
export default {
  name: 'VendorProfile',
  components: {},
}
</script>
